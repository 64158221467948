import "./Layout.css";
import { useState } from "react";
import Nav from "../Nav";
import Content from "../Content";
import Modal from "../Modal";

export default function Layout() {
  const [modal, setModal] = useState();
  return (
    <div className="Layout">
      <Nav setModal={setModal} />
      <Content setModal={setModal} />
      <Modal open={modal} setModal={setModal} />
    </div>
  );
}
