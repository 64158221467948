import "./App.css";
import AuthLayout from "./layouts/AuthLayout";
import Layout from "./layouts/Layout";
import { Switch, Route } from "react-router-dom";
import ProtectedRoute from "./layouts/ProtectedRoute";
import { NotFound } from "./screens";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/dashboard">
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        </Route>
        <Route path="/">
          <AuthLayout />
        </Route>
      </Switch>
      {/* <Layout /> */}
    </div>
  );
}

export default App;
