import { gql } from "@apollo/client";

const CREATE_OR_UPDATE_LOTTERY = gql`
  mutation lotteriesQuery($data: LotteryInputData!) {
    createOrUpdateLottery(data: $data) {
      id
      year
      opening
      deadline
      drawing
    }
  }
`;

export default CREATE_OR_UPDATE_LOTTERY;
