import "./ProtectedRoute.css";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function ProtectedRoute({ children }) {
  const history = useHistory();
  const authToken = localStorage.getItem("hola-auth-token");
  const expires = localStorage.getItem("hola-expires-at") || false;

  // console.log(authToken, expires);

  if (
    !authToken ||
    authToken === "null" ||
    !expires ||
    moment().isAfter(expires)
  ) {
    history.push("/login");
    // return;
  }

  return children;
}
