import "./Nav.css";
import { NavLink, Link } from "react-router-dom";

export default function Nav() {
  return (
    <div className="Nav">
      <div className="Nav__logo">
        <img src={"/logo-black.svg"} />
      </div>

      <div className="Nav__items" style={{ paddingTop: 0 }}>
        <div className="Nav__items__item">
          <NavLink
            to="/dashboard/applications"
            className={(isActive) =>
              "Nav__items__item__label " + (isActive ? "selected" : "")
            }
          >
            Applications
          </NavLink>
        </div>

        {/* <div className="Nav__items__item">
          <NavLink
            to="/dashboard/enquiries"
            className={(isActive) =>
              "Nav__items__item__label " + (isActive ? "selected" : "")
            }
          >
            Enquiries
          </NavLink>
        </div> */}

        <div className="Nav__items__item">
          <NavLink
            to="/dashboard/subscribers"
            className={(isActive) =>
              "Nav__items__item__label " + (isActive ? "selected" : "")
            }
          >
            Subscribers
          </NavLink>
        </div>

        <div className="Nav__items__item">
          <NavLink
            to="/dashboard/admins"
            className={(isActive) =>
              "Nav__items__item__label " + (isActive ? "selected" : "")
            }
          >
            Access Control
          </NavLink>
        </div>

        <div className="Nav__items__item">
          <div className="Nav__items__item__label">Manage Content</div>

          <div className="Nav__items">
            <div className="Nav__items__item">
              <NavLink
                to="/dashboard/lottery"
                className={(isActive) =>
                  "Nav__items__item__label " + (isActive ? "selected" : "")
                }
              >
                Lottery Details
              </NavLink>
            </div>

            <div className="Nav__items__item">
              <NavLink
                to="/dashboard/policies"
                className={(isActive) =>
                  "Nav__items__item__label " + (isActive ? "selected" : "")
                }
              >
                School Policies
              </NavLink>
            </div>

            <div className="Nav__items__item">
              <NavLink
                to="/dashboard/news"
                className={(isActive) =>
                  "Nav__items__item__label " + (isActive ? "selected" : "")
                }
              >
                News
              </NavLink>
            </div>

            <div className="Nav__items__item">
              <NavLink
                to="/dashboard/trustees"
                className={(isActive) =>
                  "Nav__items__item__label " + (isActive ? "selected" : "")
                }
              >
                Board of Trustees
              </NavLink>
            </div>

            <div className="Nav__items__item">
              <NavLink
                to="/dashboard/team"
                className={(isActive) =>
                  "Nav__items__item__label " + (isActive ? "selected" : "")
                }
              >
                Administrative Team
              </NavLink>
            </div>

            <div className="Nav__items__item">
              <NavLink
                to="/dashboard/friends"
                className={(isActive) =>
                  "Nav__items__item__label " + (isActive ? "selected" : "")
                }
              >
                Friends of Hola Board
              </NavLink>
            </div>

            <div className="Nav__items__item">
              <NavLink
                to="/dashboard/board-meetings"
                className={(isActive) =>
                  "Nav__items__item__label " + (isActive ? "selected" : "")
                }
              >
                Board Meetings
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
