import "./UpdateBoardMeeting.css";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { UPDATE_BOARD_MEETING } from "../../api";
import moment from "moment";

export default function UpdateBoardMeeting({
  setModal,
  update,
  label,
  boardMeeting,
}) {
  const [date, setDate] = useState(
    moment(boardMeeting.date).format("YYYY-MM-DD")
  );
  const [description, setDescription] = useState(boardMeeting.description);
  const [url, setUrl] = useState(boardMeeting.url);
  const [error, setError] = useState("");

  const [mutation, { loading }] = useMutation(UPDATE_BOARD_MEETING, {
    onError: (e) => {
      console.log(e);
      setError(e.message);
    },
    update,
  });

  return (
    <div className="Modal-form-wrapper">
      <div className="Modal-form-wrapper__header">
        <div className="Modal-form-wrapper__header__title">{label}</div>

        <div
          className="Modal-form-wrapper__header__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon style={{ height: "16px" }} />
        </div>
      </div>

      <form
        className="Modal-form-wrapper__form"
        onSubmit={(e) => {
          e.preventDefault();

          if (loading) return;

          if (!date || !description) {
            setError("Date and description are required.");
            return;
          }

          mutation({
            variables: {
              where: { id: boardMeeting.id },
              data: {
                date,
                description,
                url: url || null,
              },
            },
          });
        }}
      >
        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Date</div>

          <input
            type="date"
            className="Modal-form-wrapper__form__group__input"
            value={date}
            onChange={(e) => {
              // console.log(e.target.value);
              // console.log(moment(e.target.value).toDate());
              // console.log(moment(e.target.value).toISOString());
              setDate(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Description
          </div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={description}
            placeholder="eg. Regular Meeting"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Url</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          />
        </div>

        {error && (
          <div className="Modal-form-wrapper__form__error">{error}</div>
        )}

        <button
          type="submit"
          className={
            "Modal-form-wrapper__form__submit " + (loading ? "disabled" : "")
          }
        >
          Submit
        </button>
      </form>
    </div>
  );
}
