import "./CreateBoardMeeting.css";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { CREATE_BOARD_MEETING } from "../../api";
// import moment from "moment";
import uploadFile from "../../utils/uploadFile";

export default function CreateBoardMeeting({ setModal, update, label }) {
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  // const [isFilePicked, setIsFilePicked] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [mutation, { loading: creatingBoardMeeting }] = useMutation(
    CREATE_BOARD_MEETING,
    {
      onError: (e) => {
        console.log(e);
        setError(e.message);
        setLoading(false);
      },
      update,
    }
  );

  return (
    <div className="Modal-form-wrapper">
      <div className="Modal-form-wrapper__header">
        <div className="Modal-form-wrapper__header__title">{label}</div>

        <div
          className="Modal-form-wrapper__header__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon style={{ height: "16px" }} />
        </div>
      </div>

      <form
        className="Modal-form-wrapper__form"
        onSubmit={async (e) => {
          e.preventDefault();

          if (loading) return;

          if (!date || !description) {
            setError("A date and description are required.");
            return;
          }

          setLoading(true);
          // console.log(selectedFile);
          // return;

          mutation({
            variables: {
              data: {
                date,
                description,
                url: url || undefined,
              },
            },
          });
        }}
      >
        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Date</div>

          <input
            type="date"
            className="Modal-form-wrapper__form__group__input"
            value={date}
            onChange={(e) => {
              // console.log(e.target.value);
              // console.log(moment(e.target.value).toDate());
              // console.log(moment(e.target.value).toISOString());
              setDate(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Description
          </div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={description}
            placeholder="eg. Regular Meeting"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Url</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          />
        </div>

        {error && (
          <div className="Modal-form-wrapper__form__error">{error}</div>
        )}

        <button
          type="submit"
          className={
            "Modal-form-wrapper__form__submit " + (loading ? "disabled" : "")
          }
        >
          Submit
        </button>
      </form>
    </div>
  );
}
