import { gql } from "@apollo/client";

const DELETE_PRESS_ITEM = gql`
  mutation deletePressItemMutation($id: ID!) {
    deletePressItem(id: $id) {
      id
    }
  }
`;

export default DELETE_PRESS_ITEM;
