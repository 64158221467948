import "./LotteryDetails.css";
import { useQuery } from "@apollo/client";
import { LOTTERIES } from "../../api";
import moment from "moment";
import { EditLotteryDetails } from "../../components";

export default function LotteryDetails({ setModal }) {
  const currentYear = moment().year() + "";
  const lastYear = moment().subtract(1, "y").year() + "";
  const nextYear = moment().add(1, "y").year() + "";

  const { loading, error, data, variables } = useQuery(LOTTERIES, {
    variables: {
      where: {
        year: {
          in: [currentYear, nextYear, lastYear],
        },
      },
    },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const lotteries = data.lotteries;

  const lotteryItems = [
    { year: nextYear, lottery: lotteries.find((x) => x.year === nextYear) },
    {
      year: currentYear,
      lottery: lotteries.find((x) => x.year === currentYear),
    },
    { year: lastYear, lottery: lotteries.find((x) => x.year === lastYear) },
  ];

  console.log(lotteries);

  return (
    <div className="Page">
      <div className="Page__header">
        <div className="Page__header__title">Lottery Details</div>

        {/* <div className="Page__header__options">
          <div className="Page__header__options__search">
            <div className="Page__header__options__search__icon"></div>

            <input className="Page__header__options__search__input" />
          </div>

          <div className="Page__header__options__select">2023-24</div>
        </div> */}
      </div>

      <div className="Page__body">
        <div className="Page__table">
          <div className="Page__table__header">
            <div className="Page__table__row">
              <div className="Page__table__row__data" style={{ width: "20%" }}>
                Academic year
              </div>

              <div className="Page__table__row__data" style={{ width: "20%" }}>
                Opening date
              </div>

              <div className="Page__table__row__data" style={{ width: "20%" }}>
                Deadline date
              </div>

              <div className="Page__table__row__data" style={{ width: "20%" }}>
                Drawing Date
              </div>
              <div
                className="Page__table__row__data"
                style={{ width: "20%" }}
              ></div>
            </div>
          </div>

          <div className="Page__table__body">
            {lotteryItems?.map((item) => (
              <div className="Page__table__row">
                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  {item.year} - {moment(item.year).add(1, "y").year()}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  {item.lottery?.opening
                    ? moment(item.lottery.opening).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )
                    : "-"}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  {item.lottery?.deadline
                    ? moment(item.lottery.deadline).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )
                    : "-"}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  {item.lottery?.drawing
                    ? moment(item.lottery.drawing).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )
                    : "-"}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  <div
                    className="Page__table__row__data__option"
                    onClick={() => {
                      setModal(
                        <EditLotteryDetails
                          setModal={setModal}
                          lotteryItem={item}
                          label={`${item.year} - ${moment(item.year)
                            .add(1, "y")
                            .year()}`}
                          update={(
                            cache,
                            { data: { createOrUpdateLottery } }
                          ) => {
                            const q = cache.readQuery({
                              query: LOTTERIES,
                              variables,
                            });

                            const list = [...q.lotteries];
                            const itemIndex = list.findIndex(
                              (x) => x.year === item.year
                            );

                            if (itemIndex > -1) {
                              cache.modify({
                                id: cache.identify(item.lottery),
                                fields: {
                                  opening() {
                                    return createOrUpdateLottery.opening;
                                  },
                                  deadline() {
                                    return createOrUpdateLottery.deadline;
                                  },
                                  drawing() {
                                    return createOrUpdateLottery.drawing;
                                  },
                                },
                              });
                            } else {
                              cache.writeQuery({
                                query: LOTTERIES,
                                variables,
                                data: {
                                  lotteries: [
                                    ...q.lotteries,
                                    createOrUpdateLottery,
                                  ],
                                },
                              });
                            }

                            setModal(false);
                          }}
                        />
                      );
                    }}
                  >
                    Edit
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
