import { gql } from "@apollo/client";

const CREATE_ROLE = gql`
  mutation createRoleMutation($data: RoleCreateInput!) {
    createRole(data: $data) {
      id
      createdAt
      name
      email
      title
      blurb
      order
      media {
        id
        filename
      }
    }
  }
`;

export default CREATE_ROLE;
