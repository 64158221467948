import { gql } from "@apollo/client";

const POLICIES = gql`
  query policiesQuery {
    policies {
      id
      name
      url
      createdAt
      updatedAt
      showInFooter
    }
  }
`;

export default POLICIES;
