import "./Applications.css";
import { useQuery } from "@apollo/client";
import { APPLICATIONS, LOTTERIES } from "../../api";
import moment from "moment";
import { useState, Fragment } from "react";
import { ReactComponent as ChevronDown } from "../../icons/chevron-down.svg";
import makeAcademicYear from "../../utils/makeAcademicYear";
import { ClickOutside } from "../../components";
// import downloadCSV from "../../utils/downloadCSV";
import { useCSVDownloader } from "react-papaparse";
import Application from "../Application";

function ExportToCSV({ applications }) {
  const { CSVDownloader, Type } = useCSVDownloader();

  return (
    <CSVDownloader
      type={Type.Button}
      filename={"download"}
      bom={true}
      config={{
        delimiter: ",",
      }}
      data={applications}
      className="Page__header__options__button"
    >
      Export to CSV
    </CSVDownloader>
  );

  // return (
  //   <div
  //     className="Page__header__options__button"
  //     onClick={() => {
  //       downloadCSV(applications);
  //     }}
  //   >
  //     Export to CSV
  //   </div>
  // );
}

function LotterySelector({ lotteryItems, refetch }) {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const selectedItem = lotteryItems[selectedItemIndex];

  return (
    <div className="Page__header__options__select">
      <div
        className="Page__header__options__select__label"
        onClick={() => setMenuOpen(true)}
      >
        {selectedItem.label} <ChevronDown style={{ height: "12px" }} />
      </div>
      {menuOpen && (
        <ClickOutside
          onClickOutside={() => {
            setMenuOpen(false);
          }}
        >
          {(wrapperRef) => (
            <div
              className="Page__header__options__select__menu"
              ref={wrapperRef}
            >
              {lotteryItems.map((l, i) => (
                <div
                  className="Page__header__options__select__menu__item"
                  onClick={() => {
                    setSelectedItemIndex(i);
                    setMenuOpen(false);
                    refetch({
                      year: l.year,
                      waitlist: l.waitlist,
                    });
                  }}
                >
                  {l.label}
                </div>
              ))}
            </div>
          )}
        </ClickOutside>
      )}
    </div>
  );
}

function ApplicationsList({ lotteryItems, setModal }) {
  const { loading, error, data, refetch } = useQuery(APPLICATIONS, {
    variables: {
      year: lotteryItems[0].year,
      waitlist: false,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  const applications = data?.applications || [];

  return (
    <div className="Page">
      <div className="Page__header">
        <div className="Page__header__title">Applications</div>

        <div className="Page__header__options">
          {/* <div className="Page__header__options__search">
            <div className="Page__header__options__search__icon"></div>

            <input className="Page__header__options__search__input" />
          </div> */}

          <LotterySelector lotteryItems={lotteryItems} refetch={refetch} />

          <ExportToCSV applications={applications} />
        </div>
      </div>

      <div className="Page__body">
        <div className="Page__table">
          <div className="Page__table__header">
            <div className="Page__table__row">
              <div className="Page__table__row__data" style={{ width: "7%" }}>
                Id
              </div>

              <div className="Page__table__row__data" style={{ width: "63%" }}>
                Name
              </div>

              <div className="Page__table__row__data" style={{ width: "30%" }}>
                Created At
              </div>
            </div>
          </div>

          <div className="Page__table__body">
            {loading || !data ? (
              <div className="Page__table__body__placeholder">Loading</div>
            ) : (
              <Fragment>
                {!applications.length && (
                  <div className="Page__table__body__placeholder">
                    No applications found.
                  </div>
                )}
                {applications.map((application) => (
                  <div key={application.id} className="Page__table__row">
                    <div
                      className="Page__table__row__data"
                      style={{ width: "7%" }}
                    >
                      {application.id}
                    </div>

                    <div
                      className="Page__table__row__data"
                      style={{
                        width: "63%",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setModal(
                          <Application
                            id={application.id}
                            setModal={setModal}
                          />
                        );
                      }}
                    >
                      {application.fullname}
                    </div>

                    <div
                      className="Page__table__row__data"
                      style={{ width: "30%" }}
                    >
                      {moment(application.createdAt).fromNow()}
                    </div>
                  </div>
                ))}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Applications({ setModal }) {
  const { loading, error, data } = useQuery(LOTTERIES, {
    variables: {
      where: {},
    },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const lotteries = data.lotteries;
  const lotteryItems = lotteries.reduce((accum, curr) => {
    accum.push({
      label: makeAcademicYear(curr.year) + " Applications",
      year: curr.year,
      waitlist: false,
    });

    accum.push({
      label: makeAcademicYear(curr.year) + " Waitlist",
      year: curr.year,
      waitlist: true,
    });

    return accum;
  }, []);

  return <ApplicationsList lotteryItems={lotteryItems} setModal={setModal} />;

  // console.log(applications);
}
