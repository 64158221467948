import { gql } from "@apollo/client";

const SUBSCRIBERS = gql`
  query subscribersQuery {
    subscribers {
      id
      createdAt
      email
    }
  }
`;

export default SUBSCRIBERS;
