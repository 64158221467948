import "./Enquiry.css";
import { ENQUIRY } from "../../api";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

export default function Enquiry({ id, setModal }) {
  // console.log(id, typeof id);
  const { loading, error, data } = useQuery(ENQUIRY, { variables: { id } });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const enquiry = data?.enquiry;
  // console.log(application);

  return (
    <div className="Application">
      <div className="Application__header">
        <div className="Application__header__title">
          <div className="Application__header__title__primary">
            Enquiry #{id}{" "}
          </div>

          <div className="Application__header__title__secondary">
            {moment(enquiry.createdAt).fromNow()}
          </div>
        </div>

        <div
          className="Application__header__title__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>

      <div className="Application__body">
        <div className="Application__section">
          <div className="Application__section__group">
            <div className="Application__section__group__label">Fullname</div>

            <div className="Application__section__group__value">
              {enquiry.fullname || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">Email</div>

            <div className="Application__section__group__value">
              {enquiry.email || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">Phone</div>

            <div className="Application__section__group__value">
              {enquiry.phone || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">Message</div>

            <div className="Application__section__group__value">
              {enquiry.message || "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
