import { gql } from "@apollo/client";

const DELETE_ROLE = gql`
  mutation deleteRoleMutation($id: ID!) {
    deleteRole(id: $id) {
      id
    }
  }
`;

export default DELETE_ROLE;
