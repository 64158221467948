import "./EditLotteryDetails.css";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_OR_UPDATE_LOTTERY, LOTTERIES } from "../../api";
import moment from "moment";

export default function EditLotteryDetails({
  setModal,
  label,
  lotteryItem,
  update,
}) {
  console.log(lotteryItem);
  const [opening, setOpening] = useState(lotteryItem?.lottery?.opening || "");
  const [deadline, setDeadline] = useState(
    lotteryItem?.lottery?.deadline || ""
  );
  const [drawing, setDrawing] = useState(lotteryItem?.lottery?.drawing || "");
  const [error, setError] = useState("");

  const [updateLottery, { loading }] = useMutation(CREATE_OR_UPDATE_LOTTERY, {
    onError: (e) => {
      console.log(e);
      setError(e.message);
    },
    update,
  });

  return (
    <div className="Modal-form-wrapper">
      <div className="Modal-form-wrapper__header">
        <div className="Modal-form-wrapper__header__title">
          Edit {label} Lottery Details
        </div>

        <div
          className="Modal-form-wrapper__header__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon style={{ height: "16px" }} />
        </div>
      </div>

      <form
        className="Modal-form-wrapper__form"
        onSubmit={(e) => {
          e.preventDefault();

          if (loading) return;

          // if (!deadline || !drawing) {
          //   setError("All fields are required.");
          //   return;
          // }

          updateLottery({
            variables: {
              data: {
                year: lotteryItem.year,
                opening,
                deadline,
                drawing,
              },
            },
          });
        }}
      >
        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Opening Date
          </div>

          <input
            type="datetime-local"
            className="Modal-form-wrapper__form__group__input"
            value={opening}
            onChange={(e) => {
              console.log(e.target.value);
              // console.log(moment(e.target.value).toDate());
              // console.log(moment(e.target.value).toISOString());
              setOpening(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Deadline Date
          </div>

          <input
            type="datetime-local"
            className="Modal-form-wrapper__form__group__input"
            value={deadline}
            onChange={(e) => {
              console.log(e.target.value);
              // console.log(moment(e.target.value).toDate());
              // console.log(moment(e.target.value).toISOString());
              setDeadline(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Drawing Date
          </div>

          <input
            type="datetime-local"
            className="Modal-form-wrapper__form__group__input"
            value={drawing}
            onChange={(e) => {
              setDrawing(e.target.value);
            }}
          />
        </div>

        {error && (
          <div className="Modal-form-wrapper__form__error">{error}</div>
        )}

        <button
          type="submit"
          className={
            "Modal-form-wrapper__form__submit " + (loading ? "disabled" : "")
          }
        >
          Submit
        </button>
      </form>
    </div>
  );
}
