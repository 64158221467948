import { gql } from "@apollo/client";

const ROLES = gql`
  query rolesQuery($where: RolesWhereInput!) {
    roles(where: $where) {
      id
      createdAt
      name
      email
      title
      blurb
      order
      media {
        id
        filename
      }
    }
  }
`;

export default ROLES;
