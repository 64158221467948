import { DELETE_BOARD_MEETING } from "../../api";
import "./DeleteBoardMeeting.css";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

export default function DeleteBoardMeeting({
  setModal,
  update,
  boardMeeting,
  label,
}) {
  const [error, setError] = useState("");

  const [mutation, { loading }] = useMutation(DELETE_BOARD_MEETING, {
    onError: (e) => {
      console.log(e);
      setError(e.message);
    },
    update,
  });

  return (
    <div className="Modal-form-wrapper">
      <div className="Modal-form-wrapper__header">
        <div className="Modal-form-wrapper__header__title">{label}</div>

        <div
          className="Modal-form-wrapper__header__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon style={{ height: "16px" }} />
        </div>
      </div>

      <form
        className="Modal-form-wrapper__form"
        onSubmit={(e) => {
          e.preventDefault();

          if (loading) return;

          if (!boardMeeting?.id) {
            setError("Invalid news item.");
            return;
          }

          mutation({
            variables: {
              id: boardMeeting.id,
            },
          });
        }}
      >
        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Are you sure you want to delete this board meeting?
          </div>
        </div>

        {error && (
          <div className="Modal-form-wrapper__form__error">{error}</div>
        )}

        <button
          type="submit"
          className={
            "Modal-form-wrapper__form__submit " + (loading ? "disabled" : "")
          }
        >
          Yes, delete
        </button>
      </form>
    </div>
  );
}
