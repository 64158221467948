import "./Admins.css";
import { InviteAdmin } from "../../components";
import { useQuery } from "@apollo/client";
import { USERS, PENDING_INVITES } from "../../api";
import moment from "moment";

export default function Admins({ setModal }) {
  const usersQuery = useQuery(USERS);
  const invitesQuery = useQuery(PENDING_INVITES);

  if (usersQuery.error || invitesQuery.error) {
    console.log(usersQuery.error);
    console.log(invitesQuery.error);
    return <div>Error</div>;
  }

  if (
    usersQuery.loading ||
    !usersQuery.data ||
    invitesQuery.loading ||
    !invitesQuery.data
  ) {
    return <div>Loading</div>;
  }

  const users = usersQuery.data.users;
  const invites = invitesQuery.data.pendingInvites;

  return (
    <div className="Page">
      <div className="Page__header">
        <div className="Page__header__title">Access Control</div>

        <div className="Page__header__options">
          <div className="Page__header__options__search">
            <div className="Page__header__options__search__icon"></div>

            <input
              className="Page__header__options__search__input"
              placeholder="Search..."
            />
          </div>

          <div
            className="Page__header__options__button"
            onClick={() => {
              setModal(<InviteAdmin setModal={setModal} />);
            }}
          >
            Add Administrator
          </div>
        </div>
      </div>

      <div className="Page__body">
        <div className="Page__table">
          <div className="Page__table__header">
            <div className="Page__table__row">
              <div className="Page__table__row__data" style={{ width: "40%" }}>
                Name
              </div>

              <div className="Page__table__row__data" style={{ width: "35%" }}>
                Email
              </div>

              <div className="Page__table__row__data" style={{ width: "25%" }}>
                Created At
              </div>
            </div>
          </div>

          <div className="Page__table__body">
            {users?.map((user) => (
              <div className="Page__table__row">
                <div
                  className="Page__table__row__data"
                  style={{ width: "40%" }}
                >
                  {user.name}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "35%" }}
                >
                  {user.email}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "25%" }}
                >
                  {moment(user.createdAt).fromNow()}
                </div>
              </div>
            ))}

            {invites?.map((invite) => (
              <div className="Page__table__row">
                <div
                  className="Page__table__row__data"
                  style={{ width: "40%" }}
                >
                  {invite.name} (Invited)
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "35%" }}
                >
                  {invite.email}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "25%" }}
                >
                  {moment(invite.createdAt).format("Do MMM YYYY")}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
