import "./Login.css";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../api";
import { useHistory } from "react-router-dom";

export default function Login() {
  const history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("");
  const [login, { loading }] = useMutation(LOGIN, {
    onError: (e) => {
      console.log(e.message);
      setError(e.message);
    },
    update: (cache, { data: { login } }) => {
      if (!login.token || !login.expiresAt) {
        setError(
          "Something went wrong. Please check your internet connection and try again."
        );
        return;
      }

      localStorage.setItem("hola-auth-token", login.token);
      localStorage.setItem("hola-expires-at", login.expiresAt);

      history.push("/dashboard");
    },
  });

  return (
    <div className="Login">
      <div className="Login__brand">
        <img src={"/logo-black.svg"} />
      </div>

      <form
        className="Login__form"
        onSubmit={(e) => {
          e.preventDefault();

          if (loading) {
            return;
          }

          setError("");

          if (!email || !password) {
            setError("Email and password are required.");
            return;
          }

          login({ variables: { email, password } });
        }}
      >
        <div className="Login__form__group">
          <div className="Login__form__group__label">Email</div>
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setError("");
              setEmail(e.target.value);
            }}
            className="Login__form__group__input"
          />
        </div>

        <div className="Login__form__group">
          <div className="Login__form__group__label">Password</div>
          <input
            type="password"
            value={password}
            onChange={(e) => {
              setError("");
              setPassword(e.target.value);
            }}
            className="Login__form__group__input"
          />
        </div>

        {error && <div className="Login__form__error">{error}</div>}

        <button
          type="submit"
          className={"Login__form__submit " + (loading ? "disabled" : "")}
        >
          Login
        </button>
      </form>
    </div>
  );
}
