import { gql } from "@apollo/client";

const UPDATE_ROLE = gql`
  mutation updateRoleMutation($where: UniqueInput!, $data: RoleUpdateInput!) {
    updateRole(where: $where, data: $data) {
      id
      createdAt
      name
      email
      title
      blurb
      order
      media {
        id
        filename
      }
    }
  }
`;

export default UPDATE_ROLE;
