import "./CreateRole.css";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { CREATE_MEDIA, CREATE_ROLE } from "../../api";
import uploadFile from "../../utils/uploadFile";

export default function CreateRole({
  setModal,
  update,
  roleType,
  label,
  defaultOrder,
}) {
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [blurb, setBlurb] = useState("");
  const [order, setOrder] = useState(defaultOrder);
  const [selectedFile, setSelectedFile] = useState();
  // const [isFilePicked, setIsFilePicked] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [createMedia, { loading: creatingMedia }] = useMutation(CREATE_MEDIA, {
    onError: (e) => {
      console.log(e);
      setError(e.message);
      setLoading(false);
    },
  });

  const [mutation, { loading: creatingRole }] = useMutation(CREATE_ROLE, {
    onError: (e) => {
      console.log(e);
      setError(e.message);
      setLoading(false);
    },
    update,
  });

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    // setIsFilePicked(true);
  };

  return (
    <div className="Modal-form-wrapper">
      <div className="Modal-form-wrapper__header">
        <div className="Modal-form-wrapper__header__title">{label}</div>

        <div
          className="Modal-form-wrapper__header__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon style={{ height: "16px" }} />
        </div>
      </div>

      <form
        className="Modal-form-wrapper__form"
        onSubmit={async (e) => {
          e.preventDefault();

          if (loading) return;

          if (!name || !email || !title || !blurb || !selectedFile || !order) {
            setError("All fields are required.");
            return;
          }

          setLoading(true);
          // console.log(selectedFile);
          // return;

          createMedia({
            variables: {
              type: "IMAGE",
              filename: selectedFile.name,
              filetype: selectedFile.type,
              private: false,
              isExternal: false,
            },
            update: async (cache, { data: { createMedia } }) => {
              console.log("Created media:", createMedia);
              await uploadFile({
                url: createMedia.url,
                file: selectedFile,
                headers: {
                  "x-amz-acl": "public-read",
                },
              });

              mutation({
                variables: {
                  data: {
                    roleType: {
                      connect: {
                        name: roleType,
                      },
                    },
                    name,
                    email,
                    title,
                    blurb,
                    order: Number(order),
                    media: { connect: { id: createMedia.media.id } },
                  },
                },
              });
            },
          });
        }}
      >
        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Name</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={name}
            onChange={(e) => {
              // console.log(e.target.value);
              // console.log(moment(e.target.value).toDate());
              // console.log(moment(e.target.value).toISOString());
              setName(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Email</div>

          <input
            type="email"
            className="Modal-form-wrapper__form__group__input"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Title</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Profile Photo
          </div>
          {selectedFile ? (
            <div
              className="Modal-form-wrapper__form__file-placeholder"
              onClick={() => {
                setSelectedFile(false);
              }}
            >
              {selectedFile.name}
              <CloseIcon className="Modal-form-wrapper__form__file-placeholder__icon" />
            </div>
          ) : (
            <input
              type="file"
              className="Modal-form-wrapper__form__group__input"
              value=""
              title=""
              onChange={handleFileChange}
            />
          )}
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Description
          </div>

          <textarea
            className="Modal-form-wrapper__form__group__input"
            value={blurb}
            onChange={(e) => {
              setBlurb(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Order</div>

          <input
            type="number"
            className="Modal-form-wrapper__form__group__input"
            value={order}
            onChange={(e) => {
              setOrder(e.target.value);
            }}
          />
        </div>

        {error && (
          <div className="Modal-form-wrapper__form__error">{error}</div>
        )}

        <button
          type="submit"
          className={
            "Modal-form-wrapper__form__submit " + (loading ? "disabled" : "")
          }
        >
          Submit
        </button>
      </form>
    </div>
  );
}
