import { gql } from "@apollo/client";

const DELETE_POLICY = gql`
  mutation deletePolicyMutation($id: ID!) {
    deletePolicy(id: $id) {
      id
    }
  }
`;

export default DELETE_POLICY;
