import { Login, AcceptInvite, NotFound } from "../../screens";
import "./AuthLayout.css";
import { Route, Switch } from "react-router-dom";

export default function AuthLayout() {
  return (
    <div className="AuthLayout">
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/accept-invite/:token">
          <AcceptInvite />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}
