import { gql } from "@apollo/client";

const ENQUIRIES = gql`
  query enquiriesQuery {
    enquiries {
      id
      fullname
      message
      createdAt
    }
  }
`;

export default ENQUIRIES;
