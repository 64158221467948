import { gql } from "@apollo/client";

const CREATE_POLICY = gql`
  mutation createPolicyMutation($data: PolicyCreateInput!) {
    createPolicy(data: $data) {
      id
      updatedAt
      createdAt
      name
      url
      showInFooter
    }
  }
`;

export default CREATE_POLICY;
