import { gql } from "@apollo/client";

const UPDATE_BOARD_MEETING = gql`
  mutation updateBoardMeetingMutation(
    $where: UniqueInput!
    $data: BoardMeetingUpdateInput!
  ) {
    updateBoardMeeting(where: $where, data: $data) {
      id
      date
      description
      url
      isDisplayed
    }
  }
`;

export default UPDATE_BOARD_MEETING;
