import { gql } from "@apollo/client";

const PENDING_INVITES = gql`
  query pendingInvitesQuery {
    pendingInvites {
      id
      createdAt
      name
      email
    }
  }
`;

export default PENDING_INVITES;
