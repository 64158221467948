import { gql } from "@apollo/client";

const CREATE_MEDIA = gql`
  mutation createMediaMutation(
    $type: String!
    $subtype: String
    $filename: String!
    $private: Boolean
    $isExternal: Boolean
    $filetype: String!
  ) {
    createMedia(
      type: $type
      subtype: $subtype
      filename: $filename
      private: $private
      isExternal: $isExternal
      filetype: $filetype
    ) {
      url
      media {
        id
        filename
      }
    }
  }
`;

export default CREATE_MEDIA;
