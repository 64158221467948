import { gql } from "@apollo/client";

const ENQUIRY = gql`
  query enquiryQuery($id: ID!) {
    enquiry(id: $id) {
      id
      createdAt
      fullname
      email
      phone
      message
    }
  }
`;

export default ENQUIRY;
