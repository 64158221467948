import "./InviteAdmin.css";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import CREATE_ADMIN_INVITE from "../../api/auth/createAdminInvite";
import { PENDING_INVITES } from "../../api";

export default function InviteAdmin({ setModal }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [inviteAdmin, { loading }] = useMutation(CREATE_ADMIN_INVITE, {
    onError: (e) => {
      console.log(e);
      setError(e.message);
    },
    update: (cache, { data: { createAdminInviteToken } }) => {
      const q = cache.readQuery({ query: PENDING_INVITES });

      cache.writeQuery({
        query: PENDING_INVITES,
        data: {
          pendingInvites: [...q.pendingInvites, createAdminInviteToken],
        },
      });

      setSuccess({
        token: createAdminInviteToken.id,
        name: name,
        email: email,
      });

      setName("");
      setEmail("");
      setError("");

      // setModal(false);
    },
  });

  return (
    <div className="Modal-form-wrapper">
      <div className="Modal-form-wrapper__header">
        <div className="Modal-form-wrapper__header__title">Invite an Admin</div>

        <div
          className="Modal-form-wrapper__header__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon style={{ height: "16px" }} />
        </div>
      </div>

      {success && (
        <div className="Modal-form-wrapper__success-msg">
          <div>
            Invite for {success?.name} was successfully created. Please email
            the below invite link to {success?.email}.
          </div>{" "}
          <div style={{ marginTop: "16px", fontWeight: 700 }}>
            {`https://hola-admin-app-jz6bm.ondigitalocean.app/accept-invite/${success?.token}`}
          </div>
        </div>
      )}

      <form
        className="Modal-form-wrapper__form"
        onSubmit={(e) => {
          e.preventDefault();

          if (loading) return;

          if (!name || !email) {
            setError("Name and email are required.");
            return;
          }

          setSuccess(false);

          inviteAdmin({
            variables: {
              name,
              email,
            },
          });
        }}
      >
        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Name</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Email</div>

          <input
            type="email"
            className="Modal-form-wrapper__form__group__input"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>

        {error && (
          <div className="Modal-form-wrapper__form__error">{error}</div>
        )}

        <button
          type="submit"
          className={
            "Modal-form-wrapper__form__submit " + (loading ? "disabled" : "")
          }
        >
          Submit
        </button>
      </form>
    </div>
  );
}
