import { gql } from "@apollo/client";

const ACCEPT_INVITE = gql`
  mutation acceptInviteMutation($token: String!, $password: String!) {
    acceptAdminInvite(token: $token, password: $password) {
      token
      expiresAt
    }
  }
`;

export default ACCEPT_INVITE;
