import { gql } from "@apollo/client";

const BOARD_MEETINGS = gql`
  query boardMeetingsQuery {
    boardMeetings {
      id
      date
      description
      url
      isDisplayed
    }
  }
`;

export default BOARD_MEETINGS;
