import { gql } from "@apollo/client";

const APPLICATIONS = gql`
  query applicationsQuery($year: String, $waitlist: Boolean) {
    applications(year: $year, waitlist: $waitlist) {
      id
      fullname
      createdAt
    }
  }
`;

export default APPLICATIONS;
