import "./Enquiries.css";
import { useQuery } from "@apollo/client";
import { ENQUIRIES } from "../../api";
import moment from "moment";
import Enquiry from "../Enquiry";

export default function Enquiries({ setModal }) {
  const { loading, error, data } = useQuery(ENQUIRIES);

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const enquiries = data.enquiries;

  return (
    <div className="Page">
      <div className="Page__header">
        <div className="Page__header__title">Enquiries</div>

        <div className="Page__header__options">
          {/* <div className="Page__header__options__search">
            <div className="Page__header__options__search__icon"></div>

            <input className="Page__header__options__search__input" />
          </div> */}
        </div>
      </div>

      <div className="Page__body">
        <div className="Page__table">
          <div className="Page__table__header">
            <div className="Page__table__row">
              <div className="Page__table__row__data" style={{ width: "20%" }}>
                Name
              </div>

              <div className="Page__table__row__data" style={{ width: "60%" }}>
                Message
              </div>

              <div className="Page__table__row__data" style={{ width: "20%" }}>
                Created At
              </div>
            </div>
          </div>

          <div className="Page__table__body">
            {enquiries.map((enquiry) => (
              <div className="Page__table__row">
                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  {enquiry.fullname}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{
                    width: "60%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModal(<Enquiry id={enquiry.id} setModal={setModal} />);
                  }}
                >
                  {enquiry.message}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  {moment(enquiry.createdAt).fromNow()}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
