import "./Subscribers.css";
import { SUBSCRIBERS } from "../../api";
import { useQuery } from "@apollo/client";
import moment from "moment";

export default function Subscribers() {
  const { loading, error, data } = useQuery(SUBSCRIBERS);

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const subscribers = data.subscribers;

  return (
    <div className="Page">
      <div className="Page__header">
        <div className="Page__header__title">Subscribers</div>

        {/* <div className="Page__header__options">
          <div className="Page__header__options__search">
            <div className="Page__header__options__search__icon"></div>

            <input className="Page__header__options__search__input" />
          </div>

          <div className="Page__header__options__select">2023-24</div>
        </div> */}
      </div>

      <div className="Page__body">
        <div className="Page__table">
          <div className="Page__table__header">
            <div className="Page__table__row">
              <div className="Page__table__row__data" style={{ width: "80%" }}>
                Email
              </div>

              <div className="Page__table__row__data" style={{ width: "20%" }}>
                Created At
              </div>
            </div>
          </div>

          <div className="Page__table__body">
            {subscribers.map((s) => (
              <div className="Page__table__row">
                <div
                  className="Page__table__row__data"
                  style={{ width: "80%" }}
                >
                  {s.email}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  {moment(s.createdAt).fromNow()}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
