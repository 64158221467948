import { UPDATE_POLICY } from "../../api";
import "./UpdatePolicy.css";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

export default function UpdatePolicy({ setModal, update, policy }) {
  const [name, setName] = useState(policy.name);
  const [url, setUrl] = useState(policy.url);
  const [showInFooter, setShowInFooter] = useState(!!policy.showInFooter);
  const [error, setError] = useState("");

  const [mutation, { loading }] = useMutation(UPDATE_POLICY, {
    onError: (e) => {
      console.log(e);
      setError(e.message);
    },
    update,
  });

  return (
    <div className="Modal-form-wrapper">
      <div className="Modal-form-wrapper__header">
        <div className="Modal-form-wrapper__header__title">Update policy</div>

        <div
          className="Modal-form-wrapper__header__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon style={{ height: "16px" }} />
        </div>
      </div>

      <form
        className="Modal-form-wrapper__form"
        onSubmit={(e) => {
          e.preventDefault();

          if (loading) return;

          if (!name || !url) {
            setError("Name and url are required.");
            return;
          }

          mutation({
            variables: {
              where: { id: policy.id },
              data: {
                name,
                url,
                showInFooter,
              },
            },
          });
        }}
      >
        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Name</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={name}
            onChange={(e) => {
              // console.log(e.target.value);
              // console.log(moment(e.target.value).toDate());
              // console.log(moment(e.target.value).toISOString());
              setName(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Url</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Show in footer?
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              id="showInFooter"
              value="showInFooter"
              checked={showInFooter}
              onChange={(e) => {
                setShowInFooter(e.target.checked);
              }}
              style={{ cursor: "pointer" }}
            />

            <label
              style={{ marginLeft: "4px", cursor: "pointer" }}
              for="showInFooter"
            >
              {showInFooter ? "Yes" : "No"}
            </label>
          </div>
        </div>

        {error && (
          <div className="Modal-form-wrapper__form__error">{error}</div>
        )}

        <button
          type="submit"
          className={
            "Modal-form-wrapper__form__submit " + (loading ? "disabled" : "")
          }
        >
          Submit
        </button>
      </form>
    </div>
  );
}
