import "./Content.css";
import {
  Admins,
  Application,
  Applications,
  Enquiries,
  Friends,
  LotteryDetails,
  News,
  SchoolPolicies,
  Subscribers,
  Team,
  Trustees,
  NotFound,
  BoardMeetings,
} from "../../screens";
import { Route, Switch } from "react-router-dom";

export default function Content({ setModal }) {
  return (
    <div className="Content">
      <Switch>
        <Route exact path="/dashboard">
          <Applications setModal={setModal} />
        </Route>
        <Route path="/dashboard/admins">
          <Admins setModal={setModal} />
        </Route>
        <Route path="/dashboard/applications">
          <Applications setModal={setModal} />
        </Route>
        <Route path="/dashboard/application/:id">
          <Application setModal={setModal} />
        </Route>
        <Route path="/dashboard/enquiries">
          <Enquiries setModal={setModal} />
        </Route>
        <Route path="/dashboard/friends">
          <Friends setModal={setModal} />
        </Route>
        <Route path="/dashboard/lottery">
          <LotteryDetails setModal={setModal} />
        </Route>
        <Route path="/dashboard/news">
          <News setModal={setModal} />
        </Route>
        <Route path="/dashboard/policies">
          <SchoolPolicies setModal={setModal} />
        </Route>
        <Route path="/dashboard/subscribers">
          <Subscribers setModal={setModal} />
        </Route>
        <Route path="/dashboard/team">
          <Team setModal={setModal} />
        </Route>
        <Route path="/dashboard/trustees">
          <Trustees setModal={setModal} />
        </Route>
        <Route path="/dashboard/board-meetings">
          <BoardMeetings setModal={setModal} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}
