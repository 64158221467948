import { gql } from "@apollo/client";

const CREATE_PRESS_ITEM = gql`
  mutation createPressItemMutation($data: PressItemCreateInput!) {
    createPressItem(data: $data) {
      id
      createdAt
      timeToRead
      title
      author
      url
      order
      publishedAt

      media {
        id
        filename
      }
    }
  }
`;

export default CREATE_PRESS_ITEM;
