import { gql } from "@apollo/client";

const CREATE_ADMIN_INVITE = gql`
  mutation createAdminInviteMutation($name: String!, $email: String!) {
    createAdminInviteToken(name: $name, email: $email) {
      id
      name
      email
      createdAt
    }
  }
`;

export default CREATE_ADMIN_INVITE;
