import { APPLICATION, ENQUIRIES, LOTTERIES } from "../../api";
import makeAddress from "../../utils/makeAddress";
import "./Application.css";
import { useQuery } from "@apollo/client";
import moment from "moment";
import makeAcademicYear from "../../utils/makeAcademicYear";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

export default function Application({ id, setModal }) {
  // console.log(id, typeof id);
  const { loading, error, data } = useQuery(APPLICATION, { variables: { id } });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const application = data?.application;
  console.log(application);

  return (
    <div className="Application">
      <div className="Application__header">
        <div className="Application__header__title">
          <div className="Application__header__title__primary">
            Application #{id}{" "}
          </div>

          <div className="Application__header__title__secondary">
            {makeAcademicYear(application.year)}{" "}
            {application.isWaitlist ? "(Waitlist)" : ""}
          </div>
        </div>

        <div
          className="Application__header__title__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>

      <div className="Application__body">
        <div className="Application__section">
          <div className="Application__section__title">Child's Information</div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">Fullname</div>

            <div className="Application__section__group__value">
              {application.fullname || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Date of Birth(dd/mm/yyyy)
            </div>

            <div className="Application__section__group__value">
              {moment(application.dob).format("DD/MM/YYYY")}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">Gender</div>

            <div className="Application__section__group__value">
              {application.gender || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">Phone</div>

            <div className="Application__section__group__value">
              {application.phone || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Applying for grade
            </div>

            <div className="Application__section__group__value">
              {application.applyingForGrade || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Current School
            </div>

            <div className="Application__section__group__value">
              {application.currentSchool || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Current Grade
            </div>

            <div className="Application__section__group__value">
              {application.currentGrade || "-"}
            </div>
          </div>
        </div>

        <div className="Application__section">
          <div className="Application__section__title">
            Parent or Legal Guardian Information
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">Fullname</div>

            <div className="Application__section__group__value">
              {application.guardianFullname || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">Address</div>

            <div className="Application__section__group__value">
              {makeAddress(
                application.guardianAddress,
                application.guardianCity,
                application.guardianState,
                application.guardianZipCode
              ) || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">Email</div>

            <div className="Application__section__group__value">
              {application.guardianEmail || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">Phone</div>

            <div className="Application__section__group__value">
              {application.guardianPhone || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Alternate Guardian Fullname
            </div>

            <div className="Application__section__group__value">
              {application.additionalGuardianFullname || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Alternate Guardian Email
            </div>

            <div className="Application__section__group__value">
              {application.additionalGuardianEmail || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Alternate Guardian Phone
            </div>

            <div className="Application__section__group__value">
              {application.additionalGuardianPhone || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Alternate Address
            </div>

            <div className="Application__section__group__value">
              {makeAddress(
                application.alternateGuardianAddress,
                application.alternateGuardianCity,
                application.alternateGuardianState,
                application.alternateGuardianZipCode
              ) || "-"}
            </div>
          </div>
        </div>

        <div className="Application__section">
          <div className="Application__section__title">Sibling Information</div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Applying Sibling(1)
            </div>

            <div className="Application__section__group__value">
              {application.applyingSiblingFullname || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Applying Sibling(2)
            </div>

            <div className="Application__section__group__value">
              {application.alternateApplyingSiblingFullname || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Attending Sibline(1)
            </div>

            <div className="Application__section__group__value">
              {application.attendingSiblingFullname || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Attending Sibline(2)
            </div>

            <div className="Application__section__group__value">
              {application.alternateAttendingSiblingFullname || "-"}
            </div>
          </div>
        </div>

        <div className="Application__section">
          <div className="Application__section__title">Spanish Proficiency</div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Writing Fluency
            </div>

            <div className="Application__section__group__value">
              {application.writingFluencyLevel || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Reading Fluency
            </div>

            <div className="Application__section__group__value">
              {application.readingFluencyLevel || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Listening Fluency
            </div>

            <div className="Application__section__group__value">
              {application.listeningFluencyLevel || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Speaking Fluency
            </div>

            <div className="Application__section__group__value">
              {application.speakingFluencyLevel || "-"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Prior Experience
            </div>

            <div className="Application__section__group__value">
              {application.priorExperience || "-"}
            </div>
          </div>
        </div>

        <div className="Application__section">
          <div className="Application__section__title">
            Lottery Qualifications
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Low Income Preference
            </div>

            <div className="Application__section__group__value">
              {application.lowIncomePreference ? "YES" : "NO"}
            </div>
          </div>

          <div className="Application__section__group">
            <div className="Application__section__group__label">
              Ell Preference
            </div>

            <div className="Application__section__group__value">
              {application.ellPreference ? "YES" : "NO"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
