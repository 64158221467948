import { gql } from "@apollo/client";

const PRESS_ITEMS = gql`
  query pressItemsQuery {
    pressItems {
      id
      createdAt

      timeToRead
      title
      author
      url
      order
      publishedAt

      media {
        id
        filename
      }
    }
  }
`;

export default PRESS_ITEMS;
