import "./UpdateProfilePhoto.css";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { CREATE_MEDIA, UPDATE_ROLE } from "../../api";
import uploadFile from "../../utils/uploadFile";

export default function UpdateProfilePhoto({
  setModal,
  item,
  label,
  update,
  mutation,
}) {
  const [selectedFile, setSelectedFile] = useState();
  // const [isFilePicked, setIsFilePicked] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [createMedia, { loading: creatingMedia }] = useMutation(CREATE_MEDIA, {
    onError: (e) => {
      console.log(e);
      setError(e.message);
      setLoading(false);
    },
  });

  const [mutate, { loading: updating }] = useMutation(mutation, {
    onError: (e) => {
      console.log(e);
      setError(e.message);
      setLoading(false);
    },
    update: (cache, output) => {
      update(cache, output);
      setLoading(false);
    },
  });

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    // setIsFilePicked(true);
  };

  return (
    <div className="Modal-form-wrapper">
      <div className="Modal-form-wrapper__header">
        <div className="Modal-form-wrapper__header__title">{label}</div>

        <div
          className="Modal-form-wrapper__header__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon style={{ height: "16px" }} />
        </div>
      </div>

      <form
        className="Modal-form-wrapper__form"
        onSubmit={async (e) => {
          e.preventDefault();

          if (loading) return;

          if (!selectedFile) {
            setError("All fields are required.");
            return;
          }

          setLoading(true);

          // console.log(selectedFile);
          // return;

          createMedia({
            variables: {
              type: "IMAGE",
              filename: selectedFile.name,
              filetype: selectedFile.type,
              private: false,
              isExternal: false,
            },
            update: async (cache, { data: { createMedia } }) => {
              console.log("Created media:", createMedia);
              await uploadFile({
                url: createMedia.url,
                file: selectedFile,
                headers: {
                  "x-amz-acl": "public-read",
                },
              });

              mutate({
                variables: {
                  where: { id: item.id },
                  data: {
                    media: { connect: { id: createMedia.media.id } },
                  },
                },
              });
            },
          });
        }}
      >
        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Photo</div>
          {selectedFile ? (
            <div
              className="Modal-form-wrapper__form__file-placeholder"
              onClick={() => {
                setSelectedFile(false);
              }}
            >
              {selectedFile.name}
              <CloseIcon className="Modal-form-wrapper__form__file-placeholder__icon" />
            </div>
          ) : (
            <input
              type="file"
              className="Modal-form-wrapper__form__group__input"
              value=""
              title=""
              onChange={handleFileChange}
            />
          )}
        </div>

        {error && (
          <div className="Modal-form-wrapper__form__error">{error}</div>
        )}

        <button
          type="submit"
          className={
            "Modal-form-wrapper__form__submit " + (loading ? "disabled" : "")
          }
        >
          Submit
        </button>
      </form>
    </div>
  );
}
