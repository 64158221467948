import { gql } from "@apollo/client";

const DELETE_BOARD_MEETING = gql`
  mutation deleteBoardMeetingMutation($id: ID!) {
    deleteBoardMeeting(id: $id) {
      id
    }
  }
`;

export default DELETE_BOARD_MEETING;
