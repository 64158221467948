import "./UpdatePressItem.css";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { UPDATE_PRESS_ITEM } from "../../api";
import moment from "moment";

export default function UpdatePressItem({ setModal, update, pressItem }) {
  const [timeToRead, setTimeToRead] = useState(pressItem.timeToRead);
  const [title, setTitle] = useState(pressItem.title);
  const [author, setAuthor] = useState(pressItem.author);
  const [url, setUrl] = useState(pressItem.url);
  const [order, setOrder] = useState(pressItem.order);
  const [publishedAt, setPublishedAt] = useState(
    moment(pressItem.publishedAt).format("YYYY-MM-DD")
  );
  const [error, setError] = useState("");

  const [mutation, { loading }] = useMutation(UPDATE_PRESS_ITEM, {
    onError: (e) => {
      console.log(e);
      setError(e.message);
    },
    update,
  });

  return (
    <div className="Modal-form-wrapper">
      <div className="Modal-form-wrapper__header">
        <div className="Modal-form-wrapper__header__title">Update news</div>

        <div
          className="Modal-form-wrapper__header__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon style={{ height: "16px" }} />
        </div>
      </div>

      <form
        className="Modal-form-wrapper__form"
        onSubmit={(e) => {
          e.preventDefault();

          if (loading) return;

          if (!timeToRead || !url || !title || !author || !publishedAt) {
            setError("All fields are required.");
            return;
          }

          mutation({
            variables: {
              where: { id: pressItem.id },
              data: {
                timeToRead: Number(timeToRead),
                title,
                author,
                url,
                order: Number(order),
                publishedAt: moment(publishedAt).toISOString(),
              },
            },
          });
        }}
      >
        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Title</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={title}
            onChange={(e) => {
              // console.log(e.target.value);
              // console.log(moment(e.target.value).toDate());
              // console.log(moment(e.target.value).toISOString());
              setTitle(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Source</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={author}
            onChange={(e) => {
              setAuthor(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Url</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Time to read (mins)
          </div>

          <input
            type="number"
            className="Modal-form-wrapper__form__group__input"
            value={timeToRead}
            onChange={(e) => {
              setTimeToRead(e.target.value);
            }}
          />
        </div>

        {/* <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Order</div>

          <input
            type="number"
            className="Modal-form-wrapper__form__group__input"
            value={order}
            onChange={(e) => {
              setOrder(e.target.value);
            }}
          />
        </div> */}

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Published At
          </div>

          <input
            type="date"
            className="Modal-form-wrapper__form__group__input"
            value={publishedAt}
            onChange={(e) => {
              setPublishedAt(e.target.value);
            }}
          />
        </div>

        {error && (
          <div className="Modal-form-wrapper__form__error">{error}</div>
        )}

        <button
          type="submit"
          className={
            "Modal-form-wrapper__form__submit " + (loading ? "disabled" : "")
          }
        >
          Submit
        </button>
      </form>
    </div>
  );
}
