import { gql } from "@apollo/client";

const USERS = gql`
  query usersQuery {
    users {
      id
      createdAt
      name
      email
    }
  }
`;

export default USERS;
