import { gql } from "@apollo/client";

const APPLICATION = gql`
  query applicationQuery($id: Int!) {
    application(id: $id) {
      id
      createdAt
      year
      isWaitlist

      fullname
      dob
      gender
      phone
      applyingForGrade
      currentSchool
      currentGrade

      guardianFullname
      guardianAddress
      guardianCity
      guardianState
      guardianZipCode
      guardianEmail
      guardianPhone
      additionalGuardianFullname
      additionalGuardianEmail
      additionalGuardianPhone

      alternateGuardianAddress
      alternateGuardianCity
      alternateGuardianState
      alternateGuardianZipCode

      applyingSiblingFullname
      alternateApplyingSiblingFullname
      attendingSiblingFullname
      alternateAttendingSiblingFullname

      writingFluencyLevel
      readingFluencyLevel
      listeningFluencyLevel
      speakingFluencyLevel
      priorExperience

      lowIncomePreference
      ellPreference
    }
  }
`;

export default APPLICATION;
