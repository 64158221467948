import { gql } from "@apollo/client";

const UPDATE_PRESS_ITEM = gql`
  mutation updatePressItemMutation(
    $where: UniqueInput!
    $data: PressItemUpdateInput!
  ) {
    updatePressItem(where: $where, data: $data) {
      id
      createdAt
      timeToRead
      title
      author
      url
      order
      publishedAt

      media {
        id
        filename
      }
    }
  }
`;

export default UPDATE_PRESS_ITEM;
