import { PRESS_ITEMS, UPDATE_PRESS_ITEM } from "../../api";
import {
  CreatePressItem,
  UpdatePressItem,
  DeletePressItem,
  UpdateProfilePhoto,
} from "../../components";
import "./News.css";
import { useQuery } from "@apollo/client";
import moment from "moment";
import makeFileUrl from "../../utils/makeFileUrl";

export default function News({ setModal }) {
  const { loading, error, data } = useQuery(PRESS_ITEMS);

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const pressItems = data.pressItems;
  return (
    <div className="Page">
      <div className="Page__header">
        <div className="Page__header__title">Hola in the News</div>

        <div className="Page__header__options">
          {/* <div className="Page__header__options__search">
            <div className="Page__header__options__search__icon"></div>

            <input className="Page__header__options__search__input" />
          </div> */}

          <div
            className="Page__header__options__button"
            onClick={() => {
              setModal(
                <CreatePressItem
                  setModal={setModal}
                  defaultOrder={pressItems.length + 1}
                  update={(cache, { data: { createPressItem } }) => {
                    const q = cache.readQuery({
                      query: PRESS_ITEMS,
                    });

                    cache.writeQuery({
                      query: PRESS_ITEMS,
                      data: {
                        pressItems: [...q.pressItems, createPressItem],
                      },
                    });

                    setModal(false);
                  }}
                />
              );
            }}
          >
            Add news
          </div>
        </div>
      </div>

      <div className="Page__body">
        <div className="Page__table">
          <div className="Page__table__header">
            <div className="Page__table__row">
              <div className="Page__table__row__data" style={{ width: "5%" }}>
                #
              </div>
              <div className="Page__table__row__data" style={{ width: "30%" }}>
                Title
              </div>

              <div className="Page__table__row__data" style={{ width: "25%" }}>
                Source
              </div>

              <div className="Page__table__row__data" style={{ width: "20%" }}>
                Published At
              </div>
              <div
                className="Page__table__row__data"
                style={{ width: "20%" }}
              ></div>
            </div>
          </div>

          <div className="Page__table__body">
            {pressItems.map((p, i) => (
              <div className="Page__table__row">
                <div className="Page__table__row__data" style={{ width: "5%" }}>
                  {i + 1}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "30%" }}
                >
                  <div
                    className="Page__table__row__data__img"
                    onClick={() => {
                      setModal(
                        <UpdateProfilePhoto
                          setModal={setModal}
                          item={p}
                          label={"Update display photo"}
                          mutation={UPDATE_PRESS_ITEM}
                          update={(cache, { data: { updatePressItem } }) => {
                            cache.modify({
                              id: cache.identify(p),
                              fields: {
                                media() {
                                  return updatePressItem.media;
                                },
                              },
                            });

                            setModal(false);
                          }}
                        />
                      );
                    }}
                  >
                    {p.media && <img src={makeFileUrl(p.media)} />}
                  </div>
                  {p.title}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "25%" }}
                >
                  {p.author}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  {moment(p.publishedAt).fromNow()}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  <div
                    className="Page__table__row__data__option"
                    onClick={() => {
                      setModal(
                        <UpdatePressItem
                          pressItem={p}
                          setModal={setModal}
                          update={(cache, { data: { updatePressItem } }) => {
                            cache.modify({
                              id: cache.identify(p),
                              fields: {
                                title() {
                                  return updatePressItem.title;
                                },
                                author() {
                                  return updatePressItem.author;
                                },
                                timeToRead() {
                                  return updatePressItem.timeToRead;
                                },
                                url() {
                                  return updatePressItem.url;
                                },
                                order() {
                                  return updatePressItem.order;
                                },
                                publishedAt() {
                                  return updatePressItem.publishedAt;
                                },
                              },
                            });

                            setModal(false);
                          }}
                        />
                      );
                    }}
                  >
                    Edit
                  </div>

                  <div
                    className="Page__table__row__data__option"
                    onClick={() => {
                      setModal(
                        <DeletePressItem
                          pressItem={p}
                          setModal={setModal}
                          update={(cache, { data: { deletePressItem } }) => {
                            const q = cache.readQuery({
                              query: PRESS_ITEMS,
                            });

                            cache.writeQuery({
                              query: PRESS_ITEMS,
                              data: {
                                pressItems: [...q.pressItems].filter(
                                  (x) => x.id !== deletePressItem.id
                                ),
                              },
                            });

                            setModal(false);
                          }}
                        />
                      );
                    }}
                  >
                    Delete
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
