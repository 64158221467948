import { gql } from "@apollo/client";

const UPDATE_POLICY = gql`
  mutation updatePolicyMutation(
    $where: UniqueInput!
    $data: PolicyUpdateInput!
  ) {
    updatePolicy(where: $where, data: $data) {
      id
      updatedAt
      createdAt
      name
      url
      showInFooter
    }
  }
`;

export default UPDATE_POLICY;
