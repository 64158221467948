import { gql } from "@apollo/client";

const CREATE_BOARD_MEETING = gql`
  mutation createBoardMeetingMutation($data: BoardMeetingCreateInput!) {
    createBoardMeeting(data: $data) {
      id
      date
      description
      url
      isDisplayed
    }
  }
`;

export default CREATE_BOARD_MEETING;
