import "./BoardMeetings.css";
import { BOARD_MEETINGS, UPDATE_BOARD_MEETING } from "../../api";
import { useQuery } from "@apollo/client";
import {
  CreateBoardMeeting,
  UpdateBoardMeeting,
  DeleteBoardMeeting,
} from "../../components";
import moment from "moment";
import makeFileUrl from "../../utils/makeFileUrl";

export default function BoardMeetings({ setModal }) {
  const { loading, error, data, variables, refetch } = useQuery(
    BOARD_MEETINGS,
    {}
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const boardMeetings = data.boardMeetings;

  return (
    <div className="Page">
      <div className="Page__header">
        <div className="Page__header__title">Board Meetings</div>

        <div className="Page__header__options">
          {/* <div className="Page__header__options__search">
            <div className="Page__header__options__search__icon"></div>

            <input className="Page__header__options__search__input" />
          </div> */}

          <div
            className="Page__header__options__button"
            onClick={() => {
              setModal(
                <CreateBoardMeeting
                  label={"Add board meeting"}
                  setModal={setModal}
                  update={(cache, { data: { createBoardMeeting } }) => {
                    // const q = cache.readQuery({
                    //   query: BOARD_MEETINGS,
                    //   variables:{},
                    // });

                    // cache.writeQuery({
                    //   query: BOARD_MEETINGS,
                    //   variables,
                    //   data: {
                    //     roles: [...q.roles, createRole],
                    //   },
                    // });
                    refetch();

                    setModal(false);
                  }}
                />
              );
            }}
          >
            Add board meeting
          </div>
        </div>
      </div>

      <div className="Page__body">
        <div className="Page__table">
          <div className="Page__table__header">
            <div className="Page__table__row">
              <div className="Page__table__row__data" style={{ width: "5%" }}>
                #
              </div>
              <div className="Page__table__row__data" style={{ width: "25%" }}>
                Date
              </div>

              <div className="Page__table__row__data" style={{ width: "20%" }}>
                Description
              </div>

              <div className="Page__table__row__data" style={{ width: "30%" }}>
                Link(Optional)
              </div>

              <div
                className="Page__table__row__data"
                style={{ width: "20%" }}
              ></div>
            </div>
          </div>

          <div className="Page__table__body">
            {boardMeetings.map((r, i) => (
              <div className="Page__table__row">
                <div className="Page__table__row__data" style={{ width: "5%" }}>
                  {i + 1}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "25%" }}
                >
                  {moment(r.date).format("dddd MMMM DD, YYYY")}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  {r.description}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "30%" }}
                >
                  {r.url}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  <div
                    className="Page__table__row__data__option"
                    onClick={() => {
                      setModal(
                        <UpdateBoardMeeting
                          boardMeeting={r}
                          label={"Edit board meeting"}
                          setModal={setModal}
                          update={(cache, { data: { updateBoardMeeting } }) => {
                            cache.modify({
                              id: cache.identify(r),
                              fields: {
                                date() {
                                  return updateBoardMeeting.date;
                                },
                                description() {
                                  return updateBoardMeeting.description;
                                },
                                url() {
                                  return updateBoardMeeting.url;
                                },
                              },
                            });

                            setModal(false);
                          }}
                        />
                      );
                    }}
                  >
                    Edit
                  </div>

                  <div
                    className="Page__table__row__data__option"
                    onClick={() => {
                      setModal(
                        <DeleteBoardMeeting
                          boardMeeting={r}
                          label={"Delete board meeting"}
                          setModal={setModal}
                          update={(cache, { data: { deleteBoardMeeting } }) => {
                            const q = cache.readQuery({
                              query: BOARD_MEETINGS,
                              variables,
                            });

                            cache.writeQuery({
                              query: BOARD_MEETINGS,
                              variables,
                              data: {
                                boardMeetings: [...q.boardMeetings].filter(
                                  (x) => x.id !== deleteBoardMeeting.id
                                ),
                              },
                            });

                            setModal(false);
                          }}
                        />
                      );
                    }}
                  >
                    Delete
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
