import "./UpdateRole.css";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { UPDATE_ROLE } from "../../api";

export default function UpdateRole({ setModal, update, label, role }) {
  const [title, setTitle] = useState(role.title);
  const [name, setName] = useState(role.name);
  const [email, setEmail] = useState(role.email);
  const [blurb, setBlurb] = useState(role.blurb);
  const [order, setOrder] = useState(role.order);
  const [error, setError] = useState("");

  const [mutation, { loading }] = useMutation(UPDATE_ROLE, {
    onError: (e) => {
      console.log(e);
      setError(e.message);
    },
    update,
  });

  return (
    <div className="Modal-form-wrapper">
      <div className="Modal-form-wrapper__header">
        <div className="Modal-form-wrapper__header__title">{label}</div>

        <div
          className="Modal-form-wrapper__header__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon style={{ height: "16px" }} />
        </div>
      </div>

      <form
        className="Modal-form-wrapper__form"
        onSubmit={(e) => {
          e.preventDefault();

          if (loading) return;

          if (!name || !email || !title || !blurb || !order) {
            setError("All fields are required.");
            return;
          }

          mutation({
            variables: {
              where: { id: role.id },
              data: {
                name,
                email,
                title,
                blurb,
                order: Number(order),
              },
            },
          });
        }}
      >
        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Name</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={name}
            onChange={(e) => {
              // console.log(e.target.value);
              // console.log(moment(e.target.value).toDate());
              // console.log(moment(e.target.value).toISOString());
              setName(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Email</div>

          <input
            type="email"
            className="Modal-form-wrapper__form__group__input"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Title</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Description
          </div>

          <textarea
            className="Modal-form-wrapper__form__group__input"
            value={blurb}
            onChange={(e) => {
              setBlurb(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Order</div>

          <input
            type="number"
            className="Modal-form-wrapper__form__group__input"
            value={order}
            onChange={(e) => {
              setOrder(e.target.value);
            }}
          />
        </div>

        {error && (
          <div className="Modal-form-wrapper__form__error">{error}</div>
        )}

        <button
          type="submit"
          className={
            "Modal-form-wrapper__form__submit " + (loading ? "disabled" : "")
          }
        >
          Submit
        </button>
      </form>
    </div>
  );
}
