import "./CreatePressItem.css";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { CREATE_PRESS_ITEM, CREATE_MEDIA } from "../../api";
import uploadFile from "../../utils/uploadFile";
import moment from "moment";

export default function CreatePressItem({ setModal, update, defaultOrder }) {
  const [timeToRead, setTimeToRead] = useState("");
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [order, setOrder] = useState(defaultOrder);
  const [publishedAt, setPublishedAt] = useState("");
  const [loading, setLoading] = useState(false);

  const [createMedia, { loading: creatingMedia }] = useMutation(CREATE_MEDIA, {
    onError: (e) => {
      console.log(e);
      setError(e.message);
      setLoading(false);
    },
  });

  const [mutation, { loading: creatingPressItem }] = useMutation(
    CREATE_PRESS_ITEM,
    {
      onError: (e) => {
        console.log(e);
        setError(e.message);
        setLoading(false);
      },
      update,
    }
  );

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    // setIsFilePicked(true);
  };

  return (
    <div className="Modal-form-wrapper">
      <div className="Modal-form-wrapper__header">
        <div className="Modal-form-wrapper__header__title">Add news</div>

        <div
          className="Modal-form-wrapper__header__dismiss"
          onClick={() => {
            setModal(false);
          }}
        >
          <CloseIcon style={{ height: "16px" }} />
        </div>
      </div>

      <form
        className="Modal-form-wrapper__form"
        onSubmit={(e) => {
          e.preventDefault();

          if (loading) return;

          if (
            !timeToRead ||
            !url ||
            !title ||
            !author ||
            !selectedFile ||
            !publishedAt
          ) {
            setError("All fields are required.");
            return;
          }

          setLoading(true);

          createMedia({
            variables: {
              type: "IMAGE",
              filename: selectedFile.name,
              filetype: selectedFile.type,
              private: false,
              isExternal: false,
            },
            update: async (cache, { data: { createMedia } }) => {
              console.log("Created media:", createMedia);
              await uploadFile({
                url: createMedia.url,
                file: selectedFile,
                headers: {
                  "x-amz-acl": "public-read",
                },
              });

              mutation({
                variables: {
                  data: {
                    timeToRead: Number(timeToRead),
                    title,
                    author,
                    url,
                    order: Number(order),
                    publishedAt: moment(publishedAt).toISOString(),
                    media: { connect: { id: createMedia.media.id } },
                  },
                },
              });
            },
          });
        }}
      >
        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Title</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={title}
            onChange={(e) => {
              // console.log(e.target.value);
              // console.log(moment(e.target.value).toDate());
              // console.log(moment(e.target.value).toISOString());
              setTitle(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Source</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={author}
            onChange={(e) => {
              setAuthor(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Url</div>

          <input
            type="text"
            className="Modal-form-wrapper__form__group__input"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Time to read (mins)
          </div>

          <input
            type="number"
            className="Modal-form-wrapper__form__group__input"
            value={timeToRead}
            onChange={(e) => {
              setTimeToRead(e.target.value);
            }}
          />
        </div>

        {/* <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">Order</div>

          <input
            type="number"
            className="Modal-form-wrapper__form__group__input"
            value={order}
            onChange={(e) => {
              setOrder(e.target.value);
            }}
          />
        </div> */}

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Published At
          </div>

          <input
            type="date"
            className="Modal-form-wrapper__form__group__input"
            value={publishedAt}
            onChange={(e) => {
              setPublishedAt(e.target.value);
            }}
          />
        </div>

        <div className="Modal-form-wrapper__form__group">
          <div className="Modal-form-wrapper__form__group__label">
            Display Photo
          </div>
          {selectedFile ? (
            <div
              className="Modal-form-wrapper__form__file-placeholder"
              onClick={() => {
                setSelectedFile(false);
              }}
            >
              {selectedFile.name}
              <CloseIcon className="Modal-form-wrapper__form__file-placeholder__icon" />
            </div>
          ) : (
            <input
              type="file"
              className="Modal-form-wrapper__form__group__input"
              value=""
              title=""
              onChange={handleFileChange}
            />
          )}
        </div>

        {error && (
          <div className="Modal-form-wrapper__form__error">{error}</div>
        )}

        <button
          type="submit"
          className={
            "Modal-form-wrapper__form__submit " + (loading ? "disabled" : "")
          }
        >
          Submit
        </button>
      </form>
    </div>
  );
}
