import "./Team.css";
import { ROLES, UPDATE_ROLE } from "../../api";
import { useQuery } from "@apollo/client";
import {
  CreateRole,
  UpdateRole,
  DeleteRole,
  UpdateProfilePhoto,
} from "../../components";
import moment from "moment";
import makeFileUrl from "../../utils/makeFileUrl";

export default function Team({ setModal }) {
  const { loading, error, data, variables } = useQuery(ROLES, {
    variables: {
      where: {
        roleType: {
          name: "ADMINISTRATIVE_TEAM",
        },
      },
    },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const roles = data.roles;
  return (
    <div className="Page">
      <div className="Page__header">
        <div className="Page__header__title">Administrative Team</div>

        <div className="Page__header__options">
          {/* <div className="Page__header__options__search">
            <div className="Page__header__options__search__icon"></div>

            <input className="Page__header__options__search__input" />
          </div> */}

          <div
            className="Page__header__options__button"
            onClick={() => {
              setModal(
                <CreateRole
                  label={"Add team member"}
                  roleType={"ADMINISTRATIVE_TEAM"}
                  setModal={setModal}
                  defaultOrder={roles.length + 1}
                  update={(cache, { data: { createRole } }) => {
                    const q = cache.readQuery({
                      query: ROLES,
                      variables,
                    });

                    cache.writeQuery({
                      query: ROLES,
                      variables,
                      data: {
                        roles: [...q.roles, createRole],
                      },
                    });

                    setModal(false);
                  }}
                />
              );
            }}
          >
            Add team member
          </div>
        </div>
      </div>

      <div className="Page__body">
        <div className="Page__table">
          <div className="Page__table__header">
            <div className="Page__table__row">
              <div className="Page__table__row__data" style={{ width: "5%" }}>
                #
              </div>

              <div className="Page__table__row__data" style={{ width: "30%" }}>
                Name
              </div>

              <div className="Page__table__row__data" style={{ width: "25%" }}>
                Title
              </div>

              <div className="Page__table__row__data" style={{ width: "20%" }}>
                Created At
              </div>
              <div
                className="Page__table__row__data"
                style={{ width: "20%" }}
              ></div>
            </div>
          </div>

          <div className="Page__table__body">
            {roles.map((r) => (
              <div className="Page__table__row">
                <div className="Page__table__row__data" style={{ width: "5%" }}>
                  {r.order}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "30%" }}
                >
                  <div
                    className="Page__table__row__data__img"
                    onClick={() => {
                      setModal(
                        <UpdateProfilePhoto
                          setModal={setModal}
                          item={r}
                          mutation={UPDATE_ROLE}
                          label={"Update profile photo"}
                          update={(cache, { data: { updateRole } }) => {
                            cache.modify({
                              id: cache.identify(r),
                              fields: {
                                media() {
                                  return updateRole.media;
                                },
                              },
                            });

                            setModal(false);
                          }}
                        />
                      );
                    }}
                  >
                    {r.media && <img src={makeFileUrl(r.media)} />}
                  </div>
                  {r.name}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "25%" }}
                >
                  {r.title}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  {moment(r.createdAt).fromNow()}
                </div>

                <div
                  className="Page__table__row__data"
                  style={{ width: "20%" }}
                >
                  <div
                    className="Page__table__row__data__option"
                    onClick={() => {
                      setModal(
                        <UpdateRole
                          role={r}
                          label={"Edit trustee"}
                          setModal={setModal}
                          update={(cache, { data: { updateRole } }) => {
                            cache.modify({
                              id: cache.identify(r),
                              fields: {
                                name() {
                                  return updateRole.name;
                                },
                                email() {
                                  return updateRole.email;
                                },
                                title() {
                                  return updateRole.title;
                                },
                                blurb() {
                                  return updateRole.blurb;
                                },
                                order() {
                                  return updateRole.order;
                                },
                              },
                            });

                            setModal(false);
                          }}
                        />
                      );
                    }}
                  >
                    Edit
                  </div>

                  <div
                    className="Page__table__row__data__option"
                    onClick={() => {
                      setModal(
                        <DeleteRole
                          role={r}
                          label={"Delete trustee"}
                          setModal={setModal}
                          update={(cache, { data: { deleteRole } }) => {
                            const q = cache.readQuery({
                              query: ROLES,
                              variables,
                            });

                            cache.writeQuery({
                              query: ROLES,
                              variables,
                              data: {
                                roles: [...q.roles].filter(
                                  (x) => x.id !== deleteRole.id
                                ),
                              },
                            });

                            setModal(false);
                          }}
                        />
                      );
                    }}
                  >
                    Delete
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
